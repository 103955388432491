import axios from 'axios';
import { useState } from 'react';
import path from '../path';
function Login() {
    //const path='https://dev.ps-on-line.com/api/'
    const [postData,setPostData]=useState({user: '', pass:''})
    const handelOnChange = (e) => {
      const value = e.target.value
      const name = e.target.name
      //setData({...data, [name]: value })
      setPostData({...postData, [name]: value, check:'4534'})
      console.log(postData)
    }
    const handelSubmit = e => {
      e.preventDefault()
      //alert(username)
      //const postData={user:username,pass:password,check:'4534'}
      //setPostData({...postData, check:'4534'})
      console.log(postData);
      axios.post(path+'login.php',postData)
        .then(res => {
          //console.log("res data="+res.data.msg);
          //console.log("res data="+res.data.auth);
          if (res.data.res===1)
          {
            localStorage.setItem('auth',res.data.auth);
          //localStorage.setItem('fullname',res.data.fullName);
          window.location.replace('/')
          //setNewUsername(res.data.name)
          }
          else
          {
            alert(res.data.msg)
          }
          
          
  
        })
        .catch((error) => {
          console.log('error');
          console.log(error);
        })
  
    }
    //const handelOnChange = e => {
    //console.log("Users");
  
    return (<>
      <h1>تسجيل الدخول </h1>
      <form onSubmit={handelSubmit}>
        <input type="text" name="user" placeholder='Username' value={postData.user} onChange={handelOnChange} />
        <input type="password" name="pass" placeholder='Password' value={postData.pass} onChange={handelOnChange}/>
        <button type="submit" className="btn btn-success" >Submit</button>
      </form>
  
    </>);
  }
  export default Login;
  