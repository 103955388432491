//import axios from "axios";

//import { useState } from "react";
import { forwardRef, useEffect, useImperativeHandle, useState } from "react";

import Loading from "./Loading";
import axios from "axios";
import path from "../path"
import { Table } from "react-bootstrap";
import InsuranceListActions from "./listActine/InsuranceListActions";
//import { useEffect, useState } from "react";
const InsurancesTable=forwardRef(({loginOk} , ref) => 

//function InsurancesTable()
{
    //const path='https://dev.ps-on-line.com/api/'
    const [loading,setLoading]=useState(true)
    const [tableData,setTableData]=useState([])
    useImperativeHandle(ref, () => ({

        updateTable()
        {
            readData()
        }
      }))
    const readData=()=>
    {
        //const auth=localStorage.getItem('auth')
        //const postData={'auth': auth}
        
        axios.get(path+'insurances.php?do=insurance')
        .then(res => {
        console.log("data="+res.data.data);
        console.log("result="+res.data.result);
        setLoading(false)
        if (res.data.result === 1)
        {
            setTableData(res.data.data) ;
            
            
        }
        else
        {
            console.log(res.data.massage)
        }
        
        //console.log(res.data.res );
        
        })
        .catch(err => {
            console.log(err)
            setLoading(false)
        })
    }
    function removeTableItem(insurance){
        const index = tableData.indexOf(insurance);

        //const x = myArray.splice(index, 1);
        //alert("index="+index)
        setTableData(
            [
                ...tableData.slice(0, index),
                ...tableData.slice(index + 1)
              ]
        )
    }
    useEffect(()=> {
        if(loginOk)  readData()
    },[loginOk])
    if (loading) 
    {
        return (<Loading />)
    }
    if (tableData.length===0)
    {
        return (<div className="container">القائمة فارغة يمكنك انشاء تامين جديد</div>)
    }
    return (<div className="container">
        <h1>قائمة التامينات</h1>
        {/* <InsurancesTable /> */
        // className="table table-bordered border-primary"
        }
        {/* <table className="table table-dark table-striped"> */}
        <Table striped bordered hover variant="dark">
            <thead>
                <tr>
                    <th>#</th>
                    <th>رقم التامين</th>
                    <th>مالك التامين</th>
                    <th>تاريخ التامين</th>
                    <th>طراز السيارة</th>
                    <th>مالك السيارة</th>
                    <th>رقم السيارة</th>
                    <th>رقم التلفون</th>
                    <th>القيمة الفعلية</th>
                    <th>القيمة المتفق عليها</th>
                    <th>المبلغ المدفوع</th>
                    <th>المبلغ المتبقي</th>
                    <th>تاريخ الدفعه القادمة</th>
                    <th>ملاحظات</th>
                    <th>االإجراءات</th>
                </tr>
            </thead>
            <tbody>
                {
                    tableData.map((data) => (<tr key={data.id}>
                        <td>{data.id}</td>
                        <td>{data.number}</td>
                        <td>{data.insurance_owner_name}</td>
                        <td>{data.insurances_date}</td>
                        <td>{data.manufacture} {data.modle}</td>
                        <td>{data.car_owner}</td>
                        <td>{data.car_number}</td>
                        <td>{data.insurance_owner_mobile}</td>
                        <td>{data.actual_value}</td>
                        <td>{data.agreed_value}</td>
                        <td>{data.amount_paid}</td>
                        <td>{data.agreed_value-data.amount_paid}</td>
                        <td>{data.insurances_end_date}</td>
                        <td>{data.note}</td>
                        <td><InsuranceListActions insurance={data} removeTableItem={removeTableItem} /></td>
                    </tr>
                       ))
                    //console.log("tableData=" +tableData)
                }
            </tbody>

        </Table>
    </div>)
    
    
})
export default InsurancesTable